/* global MatgenGlobal, $, bootstrap */

import MatgenUIFunctions from '../../matgen-ui/ui-functions.js';

/*
function arrayToCsv(data) {
  return data
    .map(row => {
      row
        .map(String) // convert every value to String
        .map(v => v.replaceAll('"', '""')) // escape double quotes
        .map(v => `"${v}"`) // quote it
        .join(','); // comma-separated
    })
    .join('\r\n'); // rows starting on new lines
}
*/

/*function downloadBlob(content, filename, contentType) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}*/

export default {
  'question-examples': {
    hash: 'question-examples',
    route: '/question-examples',
    action: async () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else {
        if (
          MatgenGlobal.AuthUser.user &&
          MatgenGlobal.AuthUser.getUserRole() === 'user'
        ) {
          MatgenGlobal.Router.goTo('/home');
        } else {
          let questionnaire, sections, sectionQuestions, questionDisplay;
          const id = '98417569-574a-43dd-91cf-8461fed7511a';
          try {
            questionnaire = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading questionnaire data',
              promise: MatgenGlobal.Data.getQuestionnaireRecord(id),
            });

            if (Array.isArray(questionnaire) && questionnaire.length > 0) {
              questionnaire = questionnaire[0];
            }

            sections = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading questionnaire sections',
              promise: MatgenGlobal.Data.getQuestionnaireSections(id),
            });

            sectionQuestions = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading questions',
              promise: MatgenGlobal.Data.getSectionQuestions(sections[0].id),
            });

            questionDisplay = `
              <h3 style="text-align:center;margin:.75em;">Questions with examples:</h3>
              <ul class="list-group" style="max-width: 16em; margin: auto;">
                ${sectionQuestions
                  .map(
                    q => `
                  <li class="list-group-item question-with-examples" data-id="${q.id}">
                  ${q.text}
                  </li>
                  `
                  )
                  .join('')}
              </ul>
            `;

            /*tenant = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading tenant data',
              promise: MatgenGlobal.Data.getTenant(questionnaire.tenant_id),
            });*/
          } catch (e) {
            console.error(e);
            MatgenGlobal.UI.handleError(
              'API Error',
              'Failed to retrieve questionnaire data from the API.'
            );
            return false;
          }

          MatgenGlobal.UI.showPage(questionDisplay, () => {
            $(document).on('click', '.question-with-examples', async e => {
              const id = $(e.target).data('id');
              const {
                exampleContent,
                // count,
              } = await MatgenGlobal.MatgenPageLoader.start({
                message: 'Loading example form',
                promise: MatgenUIFunctions.buildExampleContent(id, false),
              });

              MatgenGlobal.M4CModal.show({
                id: 'question-examples-modal',
                title: `Examples for &quot;${
                  sectionQuestions.find(q => q.id === id).text
                }&quot;`,
                content: `
                  <div id="question-examples-div">
                    ${exampleContent}
                  </div>
                `,
                buttons: [
                  {
                    id: 'example-submit',
                    classname: 'primary btn btn-primary',
                    label: 'Add Example',
                  },
                ],
              });
            });
          });
        }
      }
    },
  },
  templates: {
    hash: 'templates',
    route: '/templates',
    action: () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tenant_id = urlParams.get('tenant_id');
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (tenant_id) {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MaterialTemplateTable.getHTML(),
          async () => {
            const data = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading template table data',
              promise: MatgenGlobal.Data.getTemplates(tenant_id),
            });
            MatgenGlobal.Tables.MaterialTemplateTable.load(data);
          }
        );
      } else {
        if (
          MatgenGlobal.AuthUser.user &&
          MatgenGlobal.AuthUser.getUserRole() === 'user'
        ) {
          MatgenGlobal.Router.goTo('/home');
        } else {
          MatgenGlobal.UI.showPage(
            MatgenGlobal.Tables.TemplateTable.getHTML(),
            async () => {
              if (
                MatgenGlobal.AuthUser.getUserRole() === 'admin' &&
                MatgenGlobal.Tables.TemplateTable.columns.findIndex(
                  c => c.data === 'tenant_name'
                ) >= 0
              ) {
                MatgenGlobal.Tables.TemplateTable.columns.splice(
                  MatgenGlobal.Tables.TemplateTable.columns.findIndex(
                    c => c.data === 'tenant_name'
                  ),
                  1
                );
                MatgenGlobal.Tables.TemplateTable.columns.find(
                  c => c.data === 'id'
                ).visible = false;
              }
              if (MatgenGlobal.AuthUser.getUserRole() === 'super') {
                $(`
                  <button id="template-import-button" type="button" data-action="import-template" class="btn btn-primary float-end table-action" data-bs-toggle="tooltip" data-placement="auto" title="Import template" style="margin-right:.5em;">Import</button>
                `).insertBefore($('button[data-action="new-template"]'));
              }

              const opts = await MatgenGlobal.AuthUser.authHeaderOpts();
              const templates = await MatgenGlobal.Amplify.API.get(
                'authenticated',
                `/editor-templates`,
                opts
              );
              await MatgenGlobal.Tables.TemplateTable.load(templates);
              const table = MatgenGlobal.Tables.TemplateTable.dataTable;

              $('#data-listing-heading').append(`
                <div id="filters">
                  <div id="filters-label">
                    <button title="Clear filters" data-bs-toggle="tooltip" data-placement="auto" id="filters-clear" type="button" class="btn btn-primary"><i class="fa-regular fa-filter-circle-xmark"></i></button>
                    <h4>Filters:</h4>
                  </div>
                </div>
                `);

              /*(table
                .columns()
                .flatten()
                .each(colIdx => {
                  // Create the select list and search operation
                  if (
                    table.column(colIdx).header().textContent !== 'Actions' &&
                    table.column(colIdx).header().textContent !== 'Tenant' &&
                    table.column(colIdx).header().textContent !== 'ID'
                  ) {
                    const select = $(`
                    <select><option value="">${
                      table.column(colIdx).header().textContent
                    }</option></select>
                    `)
                      .appendTo($('#filters'))
                      .on('change', function() {
                        table
                          .column(colIdx)
                          .search($(this).val())
                          .draw();
                      });

                    // Get the search data for the first column and add to the select list
                    table
                      .column(colIdx)
                      .cache('search')
                      .sort()
                      .unique()
                      .each(d => {
                        select.append($(`<option value="${d}">${d}</option>`));
                      });
                  }
                });*/

              table.columns().every(function() {
                const column = this;
                const title = column.header().textContent;

                if (
                  title !== 'Actions' &&
                  title !== 'T' &&
                  title !== 'ID' &&
                  title !== 'S'
                )
                  // Create input element and add event listener
                  $(`<input type="text" placeholder="${title}" />`)
                    .appendTo($('#filters'))
                    .on('keyup change clear', function() {
                      if (column.search() !== this.value) {
                        column.search(this.value).draw();
                      }
                    });
              });

              const tooltipTriggerList = document.querySelectorAll(
                '[data-bs-toggle="tooltip"]'
              );
              [...tooltipTriggerList].map(
                tooltipTriggerEl =>
                  new bootstrap.Tooltip(tooltipTriggerEl, {
                    delay: { show: 2200, hide: 0 },
                    trigger: 'hover',
                    container: 'body',
                  })
              );
            }
          );
        }
      }
    },
  },
  reports: {
    hash: 'reports',
    route: '/reports',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else {
        if (
          MatgenGlobal.AuthUser.user &&
          MatgenGlobal.AuthUser.getUserRole() === 'user'
        ) {
          MatgenGlobal.Router.goTo('/home');
        } else {
          MatgenGlobal.UI.showPage(
            MatgenGlobal.Tables.ReportTable.getHTML(),
            () => {
              MatgenGlobal.Tables.ReportTable.load();
            }
          );
        }
      }
    },
  },
  tenants: {
    hash: 'tenants',
    route: '/tenants',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.TenantTable.getHTML(),
          () => {
            MatgenGlobal.Tables.TenantTable.load();
          }
        );
      }
    },
  },
  matgenQuestionnaire: {
    hash: 'matgenQuestionnaires',
    route: '/matgen-questionnaires',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MatgenQuestionnaireTable.getHTML(),
          () => {
            MatgenGlobal.Tables.MatgenQuestionnaireTable.load();
          }
        );
      }
    },
  },
  userContentQuestionnaire: {
    hash: 'userContentQuestionnaires',
    route: '/user-content-questionnaires',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.UserContentQuestionnaireTable.getHTML(),
          () => {
            MatgenGlobal.Tables.UserContentQuestionnaireTable.load();
          }
        );
      }
    },
  },
  section: {
    hash: 'sections',
    route: '/sections',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.SectionsTable.getHTML(),
          () => {
            MatgenGlobal.Tables.SectionsTable.load();
          }
        );
      }
    },
  },
  questions: {
    hash: 'questions',
    route: '/questions',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.QuestionsTable.getHTML(),
          () => {
            MatgenGlobal.Tables.QuestionsTable.load();
          }
        );
      }
    },
  },
  answers: {
    hash: 'answers',
    route: '/answers',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.AnswersTable.getHTML(),
          () => {
            MatgenGlobal.Tables.AnswersTable.load();
          }
        );
      }
    },
  },
  templateSummary: {
    hash: 'template-summary',
    route: '/template-summary',
    action: () => {
      MatgenGlobal.UI.showPage(
        MatgenGlobal.Tables.MaterialTemplateTable.getHTML(),
        async () => {
          const data = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading template table data',
            promise: MatgenGlobal.Data.getTemplates(),
          });
          MatgenGlobal.Tables.TemplateSummaryTable.load(data);
        }
      );
    },
  },
  users: {
    hash: 'users',
    route: '/users',
    action: () => {
      MatgenGlobal.Router.core.emitRouteEvent('users');
      if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.userManagement();
      }
    },
  },
  templateEdit: {
    hash: 'templates/:id',
    route: '/templates/:id',
    after: () => {
      $(MatgenGlobal.ControllerTargetSelector).addClass('editor');
    },
    leave: done => {
      $(MatgenGlobal.ControllerTargetSelector).removeClass('editor');
      $('#sidebar').remove();
      done();
    },
    action: req => MatgenGlobal.UI.showTemplateEditor(req.id),
  },
  tenantEdit: {
    hash: 'tenant/:id',
    route: '/tenant/:id',
    action: req => MatgenGlobal.UI.showTenantEditor(req.id),
  },
  tenantQuestionnaire: {
    hash: 'tenant-questionnaire',
    route: '/tenant-questionnaire',
    action: () => MatgenUIFunctions.createQuestionnaire(),
  },
  editQuestionnaire: {
    hash: 'edit-questionnaire/:id',
    route: '/edit-questionnaire/:id',
    action: req => MatgenUIFunctions.editQuestionnaire(req.id),
  },
  tags: {
    hash: 'tags/:id',
    route: '/tags/:id',
    action: req => MatgenGlobal.UI.loadTagger(req.id),
  },
  inspector: {
    hash: 'inspect/:id',
    route: '/inspect/{id}',
    action: req => MatgenGlobal.UI.loadInspector(req.id),
  },
  optionOrder: {
    hash: 'option-order/:id',
    route: '/option-order/{id}',
    action: req => MatgenGlobal.UI.loadOptionSort(req.id),
  },
  materials: {
    hash: 'materials',
    route: '/materials',
    action: () => {
      if (MatgenGlobal.AuthUser && MatgenGlobal.AuthUser.user) {
        MatgenGlobal.Router.core.emitRouteEvent('materials');

        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MaterialTable.getHTML(),
          () => {
            MatgenGlobal.Tables.MaterialTable.load();
          }
        );
      } else {
        MatgenGlobal.Router.goTo('unauth');
      }
    },
  },
  microsites: {
    hash: 'microsites',
    route: '/microsites',
    action: () => {
      MatgenGlobal.Router.core.emitRouteEvent('microsites');
      MatgenGlobal.UI.showPage(
        MatgenGlobal.Tables.MicrositeTable.getHTML(),
        () => {
          MatgenGlobal.Tables.MicrositeTable.load();
        }
      );
    },
  },
  materialEdit: {
    hash: 'materials/:id',
    route: '/materials/:id',
    action: req => MatgenGlobal.UI.showMaterialEditor(req.id),
  },
  unauth: {
    hash: 'unauth',
    route: '/unauth',
    action: () => MatgenGlobal.UI.unauth(),
  },
  appError: {
    hash: 'error',
    route: '/error',
    action: () => MatgenGlobal.UI.appError(),
  },
  library: {
    hash: 'library',
    route: '/library',
    action: () => MatgenGlobal.UI.library(),
  },
};
